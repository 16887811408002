import Vue from 'vue'
import Vuex from 'vuex'
import Vuelidate from 'vuelidate'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faTrainSubway, faXmark } from '@fortawesome/free-solid-svg-icons'
import VTooltip from 'v-tooltip'
import store from './store'
import MiniForm from './App.vue'
import '@services/axiosDefaults'

Vue.use(VTooltip)
Vue.use(Vuex)
Vue.use(Vuelidate)
library.add(faTrainSubway, faXmark)
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false

new Vue({ store, render: h => h(MiniForm) }).$mount('#mini-form')
