var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"fields custom mb-field"},[_c('div',{staticClass:"input address"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_setup.state.inputAddress),expression:"state.inputAddress"}],ref:"addressFieldRef",staticClass:"address-input-field",class:{
        'invalid-input': _setup.v$.$error,
        'has-suggestion': _setup.dot.isSuggestionSet,
      },attrs:{"type":"text","placeholder":_setup.placeholder,"disabled":_setup.disabled,"autocomplete":"off","id":_setup.addressId,"spellcheck":"false"},domProps:{"value":(_setup.state.inputAddress)},on:{"keyup":_setup.onInputAddress,"focus":_setup.onFocusAddress,"blur":_setup.onBlurAddress,"input":function($event){if($event.target.composing)return;_vm.$set(_setup.state, "inputAddress", $event.target.value)}}}),(_setup.dot.address)?_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:({
        content: 'Очистить поле',
        trigger: 'hover click',
      }),expression:"{\n        content: 'Очистить поле',\n        trigger: 'hover click',\n      }",modifiers:{"top-center":true}}],staticClass:"icon-cross",on:{"click":_setup.clearAddress,"keypress":_setup.clearAddress}}):_vm._e(),_c('transition-group',{attrs:{"name":"fade","tag":"div","mode":"out-in"}},[(_setup.currentAddressSuggestions.length && _setup.state.isSuggestionVisible)?_c('div',{key:"suggestions-results",staticClass:"address-dropdown"},_vm._l((_setup.currentAddressSuggestions),function(suggestion,index){return _c('div',{key:index,staticClass:"dropdown-item",on:{"click":function($event){$event.preventDefault();return _setup.setAddressBySuggestion(suggestion)},"keypress":function($event){return _setup.setAddressBySuggestion(suggestion)}}},[(suggestion.isMetro)?_c('i',{staticClass:"icon-metro"}):_vm._e(),_c('span',{staticClass:"item-address"},[_vm._v(_vm._s(suggestion.input))])])}),0):_vm._e(),(
          _setup.state.isSuggestionVisible &&
          !_setup.state.inputAddress.length &&
          (_setup.draftSuggestions?.length || _setup.slots.suggestions)
        )?_c('div',{key:"base-suggestions",staticClass:"address-dropdown"},[(_setup.state.isSuggestionVisible && _setup.slots.suggestions)?_vm._t("suggestions"):_vm._e(),_vm._l((_setup.draftSuggestions),function(suggestion){return _c('div',{key:suggestion.tid,staticClass:"dropdown-item dropdown-item_draft",on:{"click":function($event){$event.preventDefault();return _setup.emit('handleDraftSuggestionClick', suggestion)},"keypress":function($event){return _setup.emit('handleDraftSuggestionClick', suggestion)}}},[_c('i',{class:{
              'icon-save': suggestion.is_suggestion,
              'icon-book': suggestion.is_addressBookItem,
              'icon-time': suggestion.is_lastOrderItem,
            }}),_c('span',{staticClass:"item-address"},[_vm._v(_vm._s(suggestion.alias))]),(!suggestion.is_lastOrderItem)?_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:({
              content: 'Удалить',
              trigger: 'hover',
            }),expression:"{\n              content: 'Удалить',\n              trigger: 'hover',\n            }",modifiers:{"top-center":true}}],staticClass:"dot-btn icon-cross",attrs:{"type":"button"},on:{"click":function($event){$event.stopPropagation();return _setup.emit('handleDeleteDraftSuggestion', suggestion)},"keypress":function($event){return _setup.emit('handleDeleteDraftSuggestion', suggestion)}}}):_vm._e()])})],2):_vm._e()])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }