<template>
  <div class="string" data-validation>
    <div class="address-container">
      <AddressInput
        @update:dot="emit('update:dot', $event)"
        :dot="dot"
        :placeholder="placeholder"
        :cityId="cityId"
        :locations="locations"
        :draftSuggestions="draftSuggestions"
        :rules="rules"
        :disabled="disabled"
        :tokenGenerator="props.tokenGenerator"
        :v$="validation.getField('address')"
        ref="addressInput"
        @handleDraftSuggestionClick="emit('handleDraftSuggestionClick', $event)"
        @handleDeleteDraftSuggestion="emit('handleDeleteDraftSuggestion', $event)"
      >
        <template v-slot:suggestions v-if="slots.suggestions">
          <slot name="suggestions" />
        </template>
      </AddressInput>
      <ValidationView :error-messages="addressErrorMessage" class="error-wrapper" />
    </div>
  </div>
</template>

<script setup>
import AddressInput from '@features/AddressInput.vue'
import ValidationView from '@components/ValidationView.vue'
import { DOT_PROPS } from '@constants'
import { toRefs, computed, ref, useSlots } from 'vue'
import { required, helpers } from '@vuelidate/validators'
import useValidation from '@hooks/useValidation'

const emit = defineEmits([
  'update:dot',
  'handleDeleteDraftSuggestion',
  'handleDraftSuggestionClick',
])

const addressInput = ref()
const slots = useSlots()
const props = defineProps({ ...DOT_PROPS })
const { placeholder, locations, cityId, dot, disabled, draftSuggestions } = toRefs(props)
const state = computed(() => addressInput.value?.state || {})
const addressId = computed(() => addressInput.value?.addressId || '')
const addressFieldRef = computed(() => addressInput.value?.addressFieldRef || null)

const addressRules = {
  required: helpers.withMessage('Необходимо указать адрес', required),
  addressStreet: helpers.withMessage(
    'Необходимо указать улицу',
    (_, { address, street, isMetro }) => (!isMetro && address ? !!street : true)
  ),
  addressBuilding: helpers.withMessage(
    'Необходимо указать номер здания',
    (_, { address, isMetro, building }) => (!isMetro && address ? !!building : true)
  ),
  ...props.rules,
}

Object.keys(addressRules).forEach(k => addressRules[k] === null && delete addressRules[k])

const rules = {
  address: addressRules,
}

const validation = useValidation(rules, dot)

const addressErrorMessage = computed(
  () => validation.getValidationErrors('address')?.[0]?.$message || []
)

const hasError = computed(() => validation.getValidationError('address'))

defineExpose({
  state,
  addressId,
  addressFieldRef,
  hasError,
  v$: validation.getField('address'),
  touchV: () => validation.touchField('address'),
})
</script>

<style scoped lang="scss">
.string {
  width: 100%;
}

.address-container {
  width: 100%;
}
</style>
