<template>
  <div class="mini-dot">
    <AddressInput
      :dot="dot"
      @update:dot="updateDot({ dot, data: $event })"
      :placeholder="computedPlaceholder"
      :tokenGenerator="tokenFunction"
      :cityId="+selectedCity.id"
      ref="addressInput"
      :locations="regions"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
import AddressInput from '@features/AddressInputWithValidation.vue'

export default {
  name: 'dotComponent',
  components: { AddressInput },
  props: {
    dot: {
      type: Object,
      required: true,
    },
    dotIndex: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['selectedCity', 'selectedLocations']),
    ...mapState(['tokenFunction']),

    computedPlaceholder() {
      return this.dotIndex === 0 ? 'Откуда' : 'Куда'
    },
    hasError() {
      return this.$refs.addressInput.hasError
    },
    regions() {
      return this.selectedLocations.map(location => location.region)
    },
  },
  methods: {
    ...mapMutations(['updateDot']),
    touchDot() {
      this.$refs.addressInput.touchV()
    },
  },
}
</script>
