<template>
  <div class="fields custom mb-field">
    <div class="input address">
      <input
        type="text"
        v-model="state.inputAddress"
        :placeholder="placeholder"
        :disabled="disabled"
        @keyup="onInputAddress"
        @focus="onFocusAddress"
        @blur="onBlurAddress"
        class="address-input-field"
        autocomplete="off"
        ref="addressFieldRef"
        :id="addressId"
        :class="{
          'invalid-input': v$.$error,
          'has-suggestion': dot.isSuggestionSet,
        }"
        spellcheck="false"
      />
      <i
        v-if="dot.address"
        class="icon-cross"
        @click="clearAddress"
        @keypress="clearAddress"
        v-tooltip.top-center="{
          content: 'Очистить поле',
          trigger: 'hover click',
        }"
      ></i>
      <transition-group name="fade" tag="div" mode="out-in">
        <!-- Suggestions search results -->
        <div
          key="suggestions-results"
          class="address-dropdown"
          v-if="currentAddressSuggestions.length && state.isSuggestionVisible"
        >
          <div
            v-for="(suggestion, index) in currentAddressSuggestions"
            @click.prevent="setAddressBySuggestion(suggestion)"
            @keypress="setAddressBySuggestion(suggestion)"
            :key="index"
            class="dropdown-item"
          >
            <i class="icon-metro" v-if="suggestion.isMetro"></i>
            <span class="item-address">{{ suggestion.input }}</span>
          </div>
        </div>
        <!-- base suggestions -->
        <div
          key="base-suggestions"
          class="address-dropdown"
          v-if="
            state.isSuggestionVisible &&
            !state.inputAddress.length &&
            (draftSuggestions?.length || slots.suggestions)
          "
        >
          <!-- Suggestions slot -->
          <slot name="suggestions" v-if="state.isSuggestionVisible && slots.suggestions" />
          <div
            v-for="suggestion of draftSuggestions"
            @click.prevent="emit('handleDraftSuggestionClick', suggestion)"
            @keypress="emit('handleDraftSuggestionClick', suggestion)"
            :key="suggestion.tid"
            class="dropdown-item dropdown-item_draft"
          >
            <i
              :class="{
                'icon-save': suggestion.is_suggestion,
                'icon-book': suggestion.is_addressBookItem,
                'icon-time': suggestion.is_lastOrderItem,
              }"
            ></i>
            <span class="item-address">{{ suggestion.alias }}</span>
            <button
              v-if="!suggestion.is_lastOrderItem"
              @click.stop="emit('handleDeleteDraftSuggestion', suggestion)"
              @keypress="emit('handleDeleteDraftSuggestion', suggestion)"
              type="button"
              class="dot-btn icon-cross"
              v-tooltip.top-center="{
                content: 'Удалить',
                trigger: 'hover',
              }"
            ></button>
          </div>
        </div>
      </transition-group>
    </div>
  </div>
</template>

<script setup>
import { toRefs, ref, reactive, computed, useSlots } from 'vue'
import { DOT_PROPS } from '@/constants'
import useAddressInput from '@hooks/useAddressInput'
import getId from '@utils/idGenerator'

const props = defineProps({ ...DOT_PROPS, v$: { type: Object, required: true } })

const { locations, disabled, placeholder, draftSuggestions, cityId, tokenGenerator, v$ } =
  toRefs(props)

const slots = useSlots()
const id = getId()
const addressId = `address-${id}`

const emit = defineEmits([
  'update:dot',
  'handleDeleteDraftSuggestion',
  'handleDraftSuggestionClick',
])

const state = reactive({
  inputAddress: '',
  isMobileDevice: false,
  isSuggestionVisible: false,
  timeoutToCleanAddress: null,
  suggestions: [],
})

const addressFieldRef = ref(null)

const dot = computed({
  get: () => props.dot,
  set: dotData => emit('update:dot', dotData), // Всегда прокидывает наверх объект {key, value}
})

const {
  setAddressBySuggestion,
  currentAddressSuggestions,
  onInputAddress,
  clearAddress,
  onBlurAddress,
  onFocusAddress,
  updateAddressInput,
} = useAddressInput({
  state,
  locations,
  dot,
  addressFieldRef,
  draftSuggestions,
  cityId,
  v$,
  tokenGenerator,
})

updateAddressInput()

defineExpose({ state, addressId, addressFieldRef }) // $refs.v$.address
</script>
<style lang="scss">
@import '@assets/scss/components/tooltips'; // Может быть не подключено в приложении, потому подключение здесь для 100% работы
</style>

<style lang="scss" scoped>
@import '@assets/scss/fonts/peshkariki'; // иконический шрифт
@import '@assets/scss/mixins/icons-mixins';

.icon-cross {
  /* stylelint-disable-next-line at-rule-no-unknown */
  @include icon-cross;
}

.icon-time,
.icon-book,
.icon-save,
.icon-time2 {
  position: relative;

  display: inline-block;

  width: 18px;
  height: 18px;
  margin-right: 10px;

  color: black;

  &::before {
    /* stylelint-disable-next-line at-rule-no-unknown */
    /* stylelint-disable-next-line at-rule-no-unknown */
    @include absolute-icon;

    width: 18px;
  }
}

.icon-time::before {
  content: url('/images/icons/time.svg');
}

.icon-book::before {
  content: url('/images/icons/notebook.svg');
}

.icon-save::before {
  font-size: 11px;
  text-align: center;
}

.dot-btn {
  position: relative;

  width: 18px;
  height: 18px !important;
  margin-left: 5px;

  font-size: 20px;
  color: #fff;

  background-color: #c9ced2;
  border: none;
  border-radius: 50%;

  transition: all 0.6s ease-out;

  &::before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-48%, -44%);

    font-family: PeshkarikiFont, sans-serif !important;
    font-size: 11px;
  }

  &:hover {
    background-color: #787a7c;
  }
}

.icon-metro::before {
  position: relative;
  top: 0;
  left: 0;
}

.string {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.address {
  position: relative;

  .address-input-field {
    width: 100%;
    padding-right: 25px !important;
  }
}

.address-dropdown {
  position: absolute;
  z-index: 10;
  top: 100%;
  left: 0;

  overflow: auto;
  display: block;

  width: 100%;
  max-height: 160px;
  margin-top: 10px;

  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 3px;
  box-shadow: 0 5px 8px 0 rgb(0 0 0 / 30%);

  span {
    align-items: center !important;
    justify-content: start !important;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
}

.building-dropdown {
  max-width: 75%;

  @media (max-width: 768px) {
    width: 200%;
  }
}

:deep(.dropdown-item) {
  cursor: pointer;

  position: relative;

  display: flex;
  align-items: center;

  width: 100%;
  padding: 5px 10px;

  color: #000;
  word-wrap: break-word;

  transition: background-color 0.3s;

  &.dropdown-item_draft {
    padding: 5px 25px 5px 10px;
  }

  .item-address {
    font-size: inherit !important;
  }

  &:hover {
    background-color: #eaeaea;
  }
}
</style>
