import { AJAX_REQUEST_HEADERS } from '@constants'
import {
  GET_ADDRESSES_API,
  GET_CLOSESET_METRO_API,
  GET_DISTANCE_FROM_METRO_API,
  GET_DISTANCE_ON_FOOT_API,
  GET_METRO_LIST_API,
} from '@constants/apiEndpoints'
import axios from 'axios'
import qs from 'querystring'

class AddressService {
  /**
   * @param {string} queryObject.query string with address
   * @param {[{region: string}]} queryObject.locations array with locations
   */
  static async getAddressSuggestions(queryObject) {
    const response = await axios.post(GET_ADDRESSES_API, queryObject)

    if (!response.data.suggestions)
      throw new Error(response.data.message || 'Ошбика при получениии адресов')
    return response.data
  }

  /**
   * @param queryObject.cityId city id number
   */
  static async getMetroList(queryObject) {
    const response = await axios.post(GET_METRO_LIST_API, queryObject, AJAX_REQUEST_HEADERS)

    if (!response.data) throw new Error(response.data.message || 'Ошбика при получениии метро')
    return response.data
  }

  /**
   * @param {Object} queryObject
   * @param {string} queryObject.address_city
   * @param {number} queryObject.city_id
   * @param {string} queryObject.address_street
   * @param {string} queryObject.address_building
   * @param {string} queryObject.lat
   * @param {string} queryObject.lng
   */
  static async getClosesetMetro(queryObject) {
    const response = await axios.post(GET_CLOSESET_METRO_API, queryObject, AJAX_REQUEST_HEADERS)

    if (!response.data)
      throw new Error(response.data.message || 'Ошбика при получениии списка метро')
    return response.data
  }

  /**
   * @param queryObject form object
   * @param queryObject.metroId: metro id string,
   * @param queryObject.lat: lat string,
   * @param queryObject.lng: lng string,
   */
  static async getDistanceFromMetro(queryObject) {
    const response = await axios.post(
      GET_DISTANCE_FROM_METRO_API,
      qs.stringify(queryObject),
      AJAX_REQUEST_HEADERS
    )

    if (!response.data.success)
      throw new Error(response.data.message || 'Ошбика при получениии данных')
    return response.data
  }

  /**
   * @typedef DotDistanceItem
   * @type Object
   * @property queryObject form object of array dots
   * @property queryObject.dateFrom: date from string,
   * @property queryObject.dateTo: date to string,
   * @property queryObject.lat: lat string,
   * @property queryObject.lng: lng string,
   *
   * @param {DotDistanceItem[]} queryObject
   */
  static async getDistanceForOnFoot(queryObject) {
    const response = await axios.post(GET_DISTANCE_ON_FOOT_API, queryObject, AJAX_REQUEST_HEADERS)

    return response.data
  }
}

export default AddressService
