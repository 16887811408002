/* eslint-disable no-undef */
/* eslint-disable no-underscore-dangle */
import Vue from 'vue'
import Vuex from 'vuex'
import Item from '@services/order/dotItemAdapter'
import Dot from '@services/order/dotAdapter'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    miniOrder: {
      dots: [],
      chosenType: {},
    },
    archiveAddressSuggestions: [],
    nextItemIndex: 1,
    tokenFunction: null,
  },
  getters: {
    appliedDotsLenght(state) {
      const arr = state.miniOrder.dots.filter(dot => dot.address !== '')

      return arr.length
    },
    selectedCity() {
      return window.cityParams
    },
    selectedLocations(state, getters) {
      return [
        {
          region: getters.selectedCity.name,
        },
        {
          region: getters.selectedCity.region_label,
        },
      ]
    },
  },
  actions: {
    async passDataToOrderForm({ state }) {
      if (typeof ym !== 'undefined') ym(41864514, 'reachGoal', 'calculate_delivery') // Метрика

      if (typeof ga !== 'undefined' && typeof ga.push === 'function') {
        ga('send', 'event', 'Raschit_dost', 'sb')
      }

      if (localStorage.getItem('miniOrder')) {
        localStorage.removeItem('miniOrder')
      }

      localStorage.setItem('miniOrder', JSON.stringify(state.miniOrder))
      window.location.href = '/orderDelivery'
    },
  },
  mutations: {
    updateDot: (_, { dot, data }) => {
      const uniformitingData = dataItem => {
        let dotData = dataItem

        if (!dotData.length) {
          dotData = [dotData]
        }

        if (dotData.find(el => el.key === 'isSetAuto') === undefined) {
          dotData.push({
            key: 'isSetAuto',
            value: false,
          })
        }

        return dotData
      }

      uniformitingData(data).forEach(attr => {
        dot[attr.key] = attr.value
      })
    },
    createDot(state, data) {
      data = { ...data }

      const createItems = function (items) {
        if (items) {
          return items.map(
            item =>
              new Item({
                ...item,
                index: state.nextItemIndex++,
              })
          )
        }

        return [new Item({ index: state.nextItemIndex++ })]
      }

      state.miniOrder.dots.push(
        new Dot({
          ...data,
          items: createItems(data.items),
        })
      )
    },
    updateToken(state, val) {
      state.tokenFunction = val
    },
    updateType(state, val) {
      state.miniOrder.chosenType = val
    },
  },
})
