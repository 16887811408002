import AddressService from '@services/address/api'
import qs from 'qs'

// Функция для объединения строк из массива
// Была введена ввиду необходимости очищения отсутствующих параметров,
// которую не удовлетворяет стандартный join
export const cleanedJoin = (array, separator = ', ') => array.filter(el => !!el).join(separator)
export const addressRegex = value => /^[а-яa-z0-9,ё():./-\s]+$/gi.test(value)

export const getSuggestionDotData = suggestion => {
  const data = []

  Object.keys(suggestion).forEach(key => {
    if ((key === 'apartment' || key === 'entrance' || key === 'storey') && suggestion[key] === '') {
      return
    }

    data.push({
      key,
      value: suggestion[key],
    })
    data.push({
      key: 'isSuggestionSet',
      value: true,
    })
  })
  return data
}

export const getClosestMetro = async (dot, cityId) => {
  const requestData = qs.stringify({
    address_city: dot.suburb,
    city_id: cityId,
    address_street: dot.street || dot.address,
    address_building: dot.building,
    lat: dot.lat,
    lng: dot.lng,
  })

  const response = await AddressService.getClosesetMetro(requestData)
  const dotData = []

  dotData.push({
    key: 'rightFormattedAddress',
    value: response.right_formatted_address,
  })
  dotData.push({
    key: 'distanceFromMetro',
    value: response.distance_from_metro,
  })
  dotData.push({
    key: 'isMetroNotFound',
    value: response.metro_not_found,
  })
  dotData.push({
    key: 'metroId',
    value: response.metro_id,
  })
  dotData.push({
    key: 'isSuburb',
    value: response.prigorod,
  })

  return dotData
}
