import { computed } from 'vue'
// eslint-disable-next-line no-unused-vars
import { useVuelidate, ValidationRuleWithParams } from '@vuelidate/core'
import { minLength, maxLength, and, helpers, required } from '@vuelidate/validators'
import useValidateRefs from '@hooks/useValidateRefs'

/**
 * Аргументы для CompositionApi, для OptionApi - поле validations() {}
 * @param {Object} rules Объект с правилами для валидации
 * @param {Object} validationObject Валидируемый объект
 * @returns {{touchField: touchField, touchList: ((function(import('vue').Component[]=): boolean)|*), getField: (function(String): Ref<Validation>), getValidationError: (function(String): *), getErrorMessages: ComputedRef<function(*): *[]>, getValidationErrors: (function(String): *), v$: Ref<Validation>, useVuelidate: ((function(*, *): ComputedRef<any>)|*|((globalConfig?: GlobalConfig) => Ref<Validation>)|(<T extends {[key in keyof Vargs]: any}, Vargs=ValidationArgs extends ValidationArgs, EState=ExtractState<Vargs> extends ExtractState<Vargs>>(validationsArgs: (Ref<Vargs> | Vargs), state: (Ref<T> | ToRefs<T> | T), globalConfig?: GlobalConfig) => Ref<Validation<Vargs, T>>)), getErrorClass: (function(String, String=): {}), getFieldValidator: (function(string, (HTMLElement|null)=): {hasErrors: function(): Boolean, touch: function(): void, elRef: HTMLElement|null})}}
 */
const useValidation = (...args) => {
  // const args = [rules, validationObject].filter(arg => arg)
  const v$ = useVuelidate(...args)

  /**
   * Валидация v$
   * @returns {boolean} успешность валидации
   */
  const touchV = () => {
    v$.value.$touch()

    return !v$.value.$error
  }

  /**
   *  получить поле v$
   * @param {String} fieldName название поля
   * @returns {import('vue').Ref<Validation>}
   */
  const getField = fieldName => {
    let validationField = v$.value

    fieldName.split('.').forEach(key => {
      validationField = validationField[key]
    })

    return validationField
  }

  /**
   * Возвращает список ошибок
   * @param {String} fieldName Название поля
   * @returns {Array} список ошибок
   */
  const getValidationErrors = fieldName => getField(fieldName)?.$errors || []
  /**
   * Возвращает имеет ли поле ошибки
   * @param {String} fieldName Название поля
   * @returns {Boolean} есть ли у поля ошибки
   */
  const getValidationError = fieldName => getField(fieldName)?.$error || false

  /**
   * запускает валидацию поля
   * @param {String} fieldName
   * @returns {Boolean} есть ли у поля ошибки
   */
  const touchField = fieldName => {
    getField(fieldName).$touch()
    return getValidationError(fieldName)
  }

  /**
   * Возвращает имеет ли поля ошибки
   * @param {String} fieldName Название поля
   * @param {String} className Название поля
   * @returns {Object.<string, boolean>}
   */
  const getErrorClass = (fieldName, className = 'invalid-input') => ({
    [className]: getValidationError(fieldName),
  })

  /**
   * Валидирует все компоненты и вовзаращает все ли они корректны
   *
   * @param {import('vue').Component[]} refsList массив компонентов
   * @returns {boolean} все ли компоненты валидны
   */
  const touchList = (refsList = null) => {
    if (!refsList) return false
    return useValidateRefs(refsList)
  }

  /**
   * Объект для валидации
   * @param {string} fieldName путь поля в this.v$, прим. 'dataForm.phone'
   * @param {null | HTMLElement} [elRef] название ссылки на элемент
   * @returns {{touch(): void, elRef}} объект для валидации
   */
  const getFieldValidator = (fieldName, elRef = null) => {
    const touch = () => touchField(fieldName)
    const hasErrors = () => getValidationError(fieldName)

    return {
      name: fieldName,
      touch,
      hasErrors,
      elRef,
    }
  }

  /**
   * Название поля
   * @param {string} fieldName
   *
   * @returns {string[]}
   */
  const getErrorMessages = computed(
    () => fieldName =>
      getValidationErrors(fieldName)
        .map(error => error?.$message)
        .filter(msg => msg)
  )

  const getMinLengthErrorMsg = val => `Минимальная длина поля ${val} символов`
  const getMaxLengthErrorMsg = val => `Максимальная длина поля ${val} символов`
  const getReqLengthErrorMsg = val => `Длина поля должна быть ${val} символов`

  const getBetweenLengthErrorMsg = (val, min, max) => {
    if (val > max) return getMaxLengthErrorMsg(val)
    if (val < min) return getMinLengthErrorMsg(val)
    return ''
  }

  /**
   * req length validator
   * @param {number} val
   * @returns {ValidationRuleWithParams}
   */
  const reqLength = val =>
    helpers.withMessage(getReqLengthErrorMsg(val), and(required, minLength(val), maxLength(val)))

  return {
    v$,
    useVuelidate,
    getField,
    touchField,
    touchV,
    getValidationErrors,
    getValidationError,
    getErrorClass,
    touchList,
    getFieldValidator,
    getErrorMessages,
    reqLength,
    getMinLengthErrorMsg,
    getMaxLengthErrorMsg,
    getBetweenLengthErrorMsg,
    getReqLengthErrorMsg,
  }
}

export default useValidation
