/* eslint-disable no-param-reassign */
import AddressService from '@services/address/api'
import { DOT_TEMPLATE, IS_TOKEN_EXCEPTED_COOKIE_NAME } from '@/constants'
import { cleanedJoin, getSuggestionDotData } from '@helpers/addressInput'
import getIsMobileDevice from '@utils/isMobileDevies'
import { computed, watch } from 'vue'
import debounce from 'lodash/debounce'

const SEARCH_DEBOUNCE = 200
const SEARCH_DEBOUNCE_MAX = 1000

const useAddressInput = ({
  state,
  locations,
  dot,
  addressFieldRef,
  cityId,
  v$,
  tokenGenerator,
}) => {
  state.isMobileDevice = getIsMobileDevice()

  const cleanedInputAddress = computed(() => {
    if (state.inputAddress.slice(-2) === ', ') {
      return state.inputAddress.slice(0, -2)
    }

    return state.inputAddress || ''
  })

  const currentAddressSuggestions = computed(() => {
    const archiveData = state.suggestions.find(data => data.query === cleanedInputAddress.value)

    return archiveData?.suggestions || []
  })

  function focusAddressField() {
    if (!addressFieldRef.value) return

    addressFieldRef.value.focus()
  }

  function isTokenExpected() {
    return document.cookie.indexOf(IS_TOKEN_EXCEPTED_COOKIE_NAME) < 0
  }

  /**
   * @param {string} address
   * @param {boolean} [exceptingToken]
   * @returns {Promise<void>}
   */
  async function searchAddress(address, exceptingToken) {
    // Игнорируем, если символов меньше нужного количества
    if (address.length < 3) return

    if (!currentAddressSuggestions.value.length) {
      const data = {
        query: address,
        locations: locations.value.map(location => ({ region: location })),
      }

      if ((tokenGenerator.value && isTokenExpected()) || (tokenGenerator.value && exceptingToken)) {
        data.token = await tokenGenerator.value()
      }

      const response = await AddressService.getAddressSuggestions(data)
      const { suggestions } = response

      state.suggestions.push({
        query: address,
        suggestions,
      })
    }
  }

  // eslint-disable-next-line no-underscore-dangle
  async function _search() {
    try {
      await searchAddress(cleanedInputAddress.value)
    } catch (e) {
      try {
        await searchAddress(cleanedInputAddress.value, true)
        // eslint-disable-next-line no-shadow
      } catch (e) {
        /* empty */
      }
    }
  }

  const search = debounce(_search, SEARCH_DEBOUNCE, { maxWait: SEARCH_DEBOUNCE_MAX })

  /**
   *
   * @param {KeyboardEvent} e
   */
  function onInputAddress(e) {
    if (e.code === 'Space') {
      search.flush()
    } else {
      search()
    }
  }

  function updateAddressInput(isClearAddress) {
    if (isClearAddress) {
      state.inputAddress = ''
    } else if (dot.value.address) {
      state.inputAddress = cleanedJoin([dot.value.address, dot.value.building]) || ''
    }
  }

  function cleanTimeout() {
    clearTimeout(state.timeoutToCleanAddress)

    state.timeoutToCleanAddress = null
  }

  function onFocusAddress() {
    // Очищаем таймаут, если поле адреса снова в фокусе
    if (state.timeoutToCleanAddress) {
      cleanTimeout()
    }

    // Если отсутствует здание — даем возможность ввести его
    if (
      state.inputAddress.length &&
      !dot.value.building &&
      !dot.value.isMetro &&
      dot.value.address.length
    ) {
      setTimeout(() => {
        state.inputAddress += ', '
        addressFieldRef.value.selectionStart = state.inputAddress.length
      })
    }

    search.flush()
    state.isSuggestionVisible = true

    // if (state.isMobileDevice) {
    // addressFieldRef.value.scrollIntoView({ block: 'start', behavior: 'smooth' })
    // scrollTo(addressFieldRef.value, 50)
    // }
  }

  // При расфокусе на поле адреса создаем таймаут для того, чтобы очистить
  // лишние, несохраненные, данные. Таймер нужен потому, что когда пользователь
  // выбирает из списка предложение — очистка сработает раньше.
  function onBlurAddress() {
    v$.value.$touch()
    state.timeoutToCleanAddress = setTimeout(() => {
      state.isSuggestionVisible = false
      // Очищаем лишние данные из поля
      updateAddressInput()
      cleanTimeout()
    }, 200)
  }

  /**
   * @typedef DotData
   * @type {Object}
   * @property {string} key - object key
   * @property {string|number|object} value - value of key
   */

  /**
   * @param {Array<DotData>} data array of objects
   */
  function updateDot(data) {
    let dataForUpdate = data

    if (!Array.isArray(data)) dataForUpdate = [data]

    dataForUpdate.forEach(dotData => {
      dot.value = dotData
    })
  }

  function cleanDot() {
    const templateDotData = Object.keys(DOT_TEMPLATE).map(key => ({
      key,
      value: DOT_TEMPLATE[key],
    }))

    templateDotData.push({ key: 'metroId', value: '' })
    updateDot(templateDotData)
  }

  // Подставляем выбранные данные об адресе в параметры точки
  async function setAddressBySuggestion(suggestion) {
    state.inputAddress = suggestion.input

    const suggestionDotData = getSuggestionDotData(suggestion)

    updateDot(suggestionDotData)

    // const closestMetro = await getClosestMetro(dot.value, cityId.value)

    // updateDot(closestMetro)

    if (!dot.value.building && !dot.value.isMetro) {
      focusAddressField()
    }
  }

  function clearAddress() {
    cleanDot()
    state.inputAddress = ''
    focusAddressField()

    updateDot({
      key: 'isSuggestionSet',
      value: false,
    })
  }

  watch(
    () => dot.value.address,
    after => {
      const isClearInput = !after // Если адрес после обновления становится пустаым (например сброс, смена города) - очищаем и инпут

      updateAddressInput(isClearInput)
    }
  )

  // При смене города сбрасываем кэш запросов
  watch(
    () => cityId.value,
    () => {
      state.suggestions = []
    }
  )

  return {
    setAddressBySuggestion,
    currentAddressSuggestions,
    onBlurAddress,
    onInputAddress,
    onFocusAddress,
    updateAddressInput,
    clearAddress,
  }
}

export default useAddressInput
